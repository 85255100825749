<template>
    <div class="main">
        <!-- 客服按钮 -->
        <a href="tencent://message/?uin=30666956&Site=&Menu-=yes" class="contact"></a>
        <div class="banner bg1">
            <div class="content">
                <div class="fl txt_box">
                    <p class="header_title">专注房地产项目</p>
                    <p class="header_title">精准画像</p>
                    <p class="desc">为项目审核与内控提供数据规范性审查与合理性分析</p>
                    <img @click="toLogin" class="tologin" src="../../assets/img/index_btn1.png" alt="">
                    <img class="circle" src="../../assets/img/index_img2.png" alt="">

                </div>
                <img class="fr" style="margin-top:177px" src="../../assets/img/index_img1.png" alt="">
            </div>
        </div>
        <div class="bg2"></div>
        <div class="bg3"></div>
        <div class="bg4"></div>
        <div class="bg5"></div>
        <div class="bg6"></div>
        <div class="banner">
            <div class="content">
                <div class="item">
                    <img src="../../assets/img/index_img3.png" alt="">
                    <span>辅助审核</span>
                </div>
                <div class="item">
                    <img src="../../assets/img/index_img4.png" style="margin-top:15px;margin-left:38px">
                    <span>客观分析</span>
                </div>
                <div class="item">
                    <img src="../../assets/img/index_img5.png" style="margin-left:10px">
                    <span>数据支撑</span>
                </div>
            </div>
        </div>
        <div class="banner">
            <div class="content">
              <div class="fl">
                  <div class="title_box">
                      <img src="../../assets/img/index_img6.png" alt="">
                      <p class="bold_t">产品概述</p>
                      <p class="desc_t">Product overview</p>
                  </div>
                  <div class="text_box">
                      <p>在产业数字化浪潮下，我们更愿意陪伴和带领企业进行数字化转型。</p>
                      <p>通过数字化产品结合应用<span class="blue">多方源数据</span>，从法律法规<span class="blue">合规性</span>与数据<span class="blue">合理性</span>视角，采用<span class="blue">数据化方法</span>、<span class="blue">成熟的数据分析模型</span>支撑房地产项目审核、鉴证、内控，<span class="blue">识别异常</span>数据，<span class="blue">定位潜在风险</span>，<span class="blue">提升清算效率</span>、<span class="blue">降低服务成本</span>、<span class="blue">提升获客能力</span>。  </p>
                  </div>
              </div>
              <div class="fr img_box">
                  <img class="img_item1" src="../../assets/img/imglist/item1.png" >
                  <img class="img_item2" src="../../assets/img/imglist/item2.png" >
                  <img class="img_item3" src="../../assets/img/imglist/item3.png" >
                  <img class="img_item4" src="../../assets/img/imglist/item4.png" >
                  <img class="img_item5" src="../../assets/img/imglist/item5.png" >
                  <img class="img_item6" src="../../assets/img/imglist/item6.png" >
                  <img class="img_item7" src="../../assets/img/imglist/item7.png" >
                  <img class="img_item8" src="../../assets/img/imglist/item8.png" >
                  <img class="img_item9" src="../../assets/img/imglist/item9.png" >
                  <img class="img_item10" src="../../assets/img/imglist/item10.png" >
                  <img class="img_item11" src="../../assets/img/imglist/item11.png" >
              </div>
            </div>
        </div>
        <div class="banner">
            <div class="content">
              <div class="fr" style="margin-right:60px">
                  <div class="title_box" style="text-align: right;">
                      <img src="../../assets/img/index_img6.png" alt="">
                      <p class="bold_t">云策能帮您解决</p>
                      <p class="desc_t">We can help you solve it </p>
                  </div>
              </div>
            </div>
        </div>
        <div class="banner">
            <div class="content">
                <el-carousel  indicator-position="none" :interval="8000" arrow="never" ref="cardShow">
                    <div class="caro_btn">
                        <img @click="arrowClick('left')" src="../../assets/img/index_left.png" alt="">
                        <img @click="arrowClick('right')" src="../../assets/img/index_right.png" alt="">
                    </div>
                    <el-carousel-item>
                        <img src="../../assets/img/index_img7.png" class="caro_icon">
                        <p class="caro_title">数据问题</p>
                        <p class="caro_desc">解决数据广、内容多、无处找、耗精力、低效率等问题</p>
                        <div class="caro_box">
                            <div class="caro_item" style="margin-right:132px">
                                <div class="caro_item_title"><span class="bor"></span>项目信息</div>
                                <p>提供项目的建设方和施工方背景、历史建设项目等数据</p>
                                <img class="img_num" src="../../assets/img/index_01.png" alt="">
                            </div>
                            <div class="caro_item">
                                <div class="caro_item_title"><span class="bor"></span>开放数据</div>
                                <p>提供土地、网签、房价、政策等开放数据</p>
                                <img class="img_num" src="../../assets/img/index_02.png" alt="">
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <img src="../../assets/img/index_img7.png" class="caro_icon">
                        <p class="caro_title">风险问题</p>
                        <p class="caro_desc">聚合散落在众多 网络平台的企业和项目负面信息</p>
                        <div class="caro_box">
                            <div class="caro_item" style="margin-right:132px">
                                <div class="caro_item_title"><span class="bor"></span>舆情风险</div>
                                <p>聚集企业和项目历史负面新闻和舆论信息</p>
                                <img class="img_num" src="../../assets/img/index_01.png" alt="">
                            </div>
                            <div class="caro_item">
                                <div class="caro_item_title"><span class="bor"></span>司法风险</div>
                                <p>展示企业和项目相关司法案例信息</p>
                                <img class="img_num" src="../../assets/img/index_02.png" alt="">
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <img src="../../assets/img/index_img7.png" class="caro_icon">
                        <p class="caro_title">测算问题</p>
                        <p class="caro_desc">提供区域核心数据、算法模型，助力测算</p>
                        <div class="caro_box">
                            <div class="caro_item" style="margin-right:132px">
                                <div class="caro_item_title"><span class="bor"></span>测算数据</div>
                                <p>提供区域网签、土地数据、造价信息等信息</p>
                                <img class="img_num" src="../../assets/img/index_01.png" alt="">
                            </div>
                            <div class="caro_item">
                                <div class="caro_item_title"><span class="bor"></span>算法模型</div>
                                <p>智能化、自动化进行税收测算并出具报告</p>
                                <img class="img_num" src="../../assets/img/index_02.png" alt="">
                            </div>
                        </div>
                    </el-carousel-item>
               
                </el-carousel>
            </div>
        </div>
        <div class="banner">
            <div class="content">
              <div style="margin-right:60px">
                  <div class="title_box tec_box">
                      <img src="../../assets/img/index_img8.png" class="fl">
                      <p class="bold_t">技术方案</p>
                      <p class="desc_t">Technical Solutions</p>
                  </div>
              </div>
              <img src="../../assets/img/index_img9.png" style="margin:0 auto;display:block;">
            </div>
        </div>
        <div class="banner">
            <div class="content">
              <div class="adv_box">
                  <div class="title_box adv_title_box">
                      <img src="../../assets/img/index_img10.png">
                      <p class="bold_t">产品优势</p>
                      <p class="desc_t">Product advantages</p>
                  </div>
                  <img class="item1" src="../../assets/img/index_item1.png" alt="">
                  <img class="item2" src="../../assets/img/index_item2.png" alt="">
                  <img class="item3" src="../../assets/img/index_item3.png" alt="">
                  <img class="item4" src="../../assets/img/index_item4.png" alt="">
              </div>
            </div>
        </div>
        <div class="banner">
            <div class="content friend">
                <img src="../../assets/img/index_img11.png" alt="">
                <img src="../../assets/img/index_img12.png" alt="">
            </div>
        </div>
        <foot></foot>
    </div>
</template>
<script>
import foot from '@/page/website/component/foot.vue';
export default {
    components: {
        foot,
    },
    data() {
        return {}
    },
    created() {
        
    },
    mounted() {
    },
    methods: {
        arrowClick(val) {
            if(val === 'right') {
                this.$refs.cardShow.next()
            } else {
                this.$refs.cardShow.prev()
            }
        },
        toLogin(){
            var url = 'http://' + window.location.host + '/#/login'
            window.open(url,'_blank')
        }
        
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}

.main{
    width: 100%;
    overflow-y: scroll;
    position: relative;
}
.banner{
    width: 100%;
}
.fl{
    float: left;
}
.fr{
    float: right;
}
.content{
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    .txt_box{
        height: 795px;
        box-sizing: border-box;
        padding-top: 240px;
        position: relative;
        padding-left: 26px;
    }
    .header_title{
        color: #fff;
        font-size: 44px;
        line-height: 70px;
        z-index: 1;
        position: relative;
    }
    .desc{
        color: #fff;
        font-size: 20px;
        margin-top: 28px;
    }
    .circle{
        position: absolute;
        left: 0;
        top: 224px;
    }
    .item{
        float: left;
        width: 280px;
        height: 111px;
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 111px;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
        margin: 6px 136px 140px 6px;
        border-radius: 10px;
        background: #EBF0FC;
        img{
            float: left;
            margin: 9px 0 0 28px;
        }
        span{
            float: right;
            height: 111px;
            line-height: 111px;
            margin-right: 33px;
        }
    }
    .item:last-child{
        margin-right: 0;
    }
    .title_box{
        .bold_t{
            font-size: 36px;
            font-weight: 600;
            color: #333333;
            line-height: 50px;
        }
        .desc_t{
            font-size: 18px;
            font-weight: 600;
            color: #B7B7B7;
            line-height: 25px;
        }
    }
    .tec_box{
        width: 264px;
        margin: 160px auto 39px;
        img{
            margin-right: 18px;
        }
    }
    .text_box{
        width: 380px;
        margin-bottom: 135px;
        p{
            font-size: 18px;
            font-weight: 600;
            color: #666666;
            line-height: 50px;
        }
        .blue{
            color: #255BC7;
        }
    }
    .img_box{
        width: 770px;
        height: 540px;
        margin-left: 19px;
        position: relative;
        img{
            position: absolute;
        }
        .img_item1{
            left: 77px;
            top: 125px;
            z-index: 10;
            animation: mymove 4s infinite;	
        }
        @keyframes mymove {
            55% {
                top: 140px;
            }
        }	
        .img_item2{
            left: 365px;
            top: 160px;
            animation: mymove2 4s infinite;	
            animation-delay:1s;
        }
        @keyframes mymove2 {
            55% {
                top: 175px;
            }
        }	
        .img_item3{
            left: 160px;
            top: 315px;
            animation: mymove3 4s infinite;	
            animation-delay:2s;
        }
        @keyframes mymove3 {
            55% {
                top: 330px;
            }
        }	
        .img_item4{
            left: 428px;
            top: 325px;
            z-index: 1;
            animation: mymove4 4s infinite;	
            animation-delay:1s;
        }
        @keyframes mymove4 {
            55% {
                top: 340px;
            }
        }	
        .img_item5{
            left: 20px;
            top: 306px;
            z-index: 1;
            animation: mymove5 4s infinite;	
            animation-delay:1s;

        }
        @keyframes mymove5 {
            55% {
                top: 321px;
            }
        }
        .img_item6{
            left: 345px;
            top: 35px;
            animation: mymove6 4s infinite;	
            animation-delay:2s;
        }
        @keyframes mymove6 {
            55% {
                top: 50px;
            }
        }
        .img_item7{
            left: 252px;
            top: 35px;
        }
        .img_item8{
            left: 492px;
            top: 90px;
        }
        .img_item9{
            left: 0px;
            top: 370px;
            z-index: 0;
        }
        .img_item10{
            left: 30px;
            top: 90px;
            z-index: 0;
        }
        .img_item11{
            left: 545px;
            top: 383px;
            z-index: 0;
        }
    }
    .el-carousel{
        height: 360px;
        position: relative;
        /deep/.el-carousel__container{
            height: 360px !important;
        }
        .caro_btn{
            position: absolute;
            right: 108px;
            top: 0;
            z-index: 99;
            img{
                float: left;
                cursor: pointer;
            }
        }
       
        .caro_icon{
            margin-left: 15px;
        }
        .caro_title{
            font-size: 28px;
            font-weight: 600;
            color: #2656C6;
            line-height: 40px;
            padding-left: 34px;
            margin-top: 20px;
            margin-bottom: 6px;
        }
        .caro_desc{
            font-size: 18px;
            font-weight: 500;
            color: #2656C6;
            line-height: 25px;
            padding-left: 34px;
        }
        .caro_box{
            margin-top: 46px;
            width: 100%;
            padding-left: 66px;
            padding-bottom: 10px;
            box-sizing: border-box;
            overflow: hidden;
            .caro_item{
                width: 439px;
                height: 162px;
                background: #FFFFFF;
                box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
                float: left;
                box-sizing: border-box;
                padding-left: 41px;
                position: relative;
                .caro_item_title{
                    height: 28px;
                    line-height: 28px;
                    font-size: 20px;
                    font-weight: 600;
                    color: #333333;
                    margin-top: 26px;
                    .bor{
                        width: 8px;
                        height: 28px;
                        float: left;
                        background: linear-gradient(180deg, #749EFF 0%, #2656C6 100%);
                        margin-right: 8px;
                    }
                }
                p{
                    width: 343px;
                    height: 50px;
                    font-size: 18px;
                    color: #666666;
                    line-height: 25px;
                    margin-left: 16px;
                    margin-top: 18px;
                }
                .img_num{
                    position: absolute;
                    right: 11px;
                    top: 0;
                }
            }
        }

    }
    .adv_box{
        margin-top: 137px;
        width: 1048px;
        float: right;
        height: 894px;
        position: relative;
        .adv_title_box{
            position: absolute;
            top: 272px;
            left: 119px;
            p{
                color: #fff !important;
                padding-left: 14px;
            }
        }
        .item1{
            position: absolute;
            top: 79px;
            left: 400px;
        }
        .item2{
            position: absolute;
            top: 129px;
            left: 745px;
        }
        .item3{
            position: absolute;
            top: 434px;
            left: 400px;
        }
        .item4{
            position: absolute;
            top: 484px;
            left: 745px;
        }
    }
    
}
.friend{
    text-align: center;
    padding-top: 101px;
    img{
        margin-bottom: 87px;
    }
}
.bg1{
    background: url('../../assets/img/index_bg1.png') no-repeat;
}
.bg2{
    width: 1404px;
    height: 879px;
    position: absolute;
    right: 0;
    top: 900px;
    background: url('../../assets/img/index_bg2.png') no-repeat;
    z-index: 0;
}
.bg3{
    width: 1404px;
    height: 879px;
    position: absolute;
    left: 0;
    top: 1500px;
    background: url('../../assets/img/index_bg3.png') no-repeat;
    z-index: 0;
}
.bg4{
    width: 1404px;
    height: 879px;
    position: absolute;
    right: 0;
    top: 2150px;
    background: url('../../assets/img/index_bg4.png') no-repeat;
    z-index: 0;
}
.bg5{
    width: 1404px;
    height: 879px;
    position: absolute;
    right: 0;
    top: 3164px;
    background: url('../../assets/img/index_bg5.png') no-repeat;
    z-index: 0;
}
.bg6{
    width: 1772px;
    height: 1302px;
    position: absolute;
    left: 0;
    top: 4200px;
    background: url('../../assets/img/index_bg6.png') no-repeat;
    z-index: 0;
}
.tologin{
    margin-top: 62px;
    cursor: pointer;
}
.contact{
    width: 198px;
    height: 84px;
    display: block;
    position: fixed;
    right: 0;
    top: 600px;
    background: url('../../assets/img/contact.png') no-repeat;
    z-index: 99;
}
</style>